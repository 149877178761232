import {Link} from "gatsby";
import React from "react";

const NoticeCard = ({title, excerpt, slug, author, date, tags}) => (
  <div className="my-1 px-1 w-full lg:w-1/2 md:my-4 md:px-4">
    <article className="overflow-hidden rounded-lg shadow-lg">
      <Link to={`${slug}`}>
        <header className={"py-2 px-4 border-b-1 border-gray-300"}>
          <h3 className={"font-bold text-xl"}>{title}</h3>
        </header>
      </Link>
      <main className={"p-4 text-gray-700"}>
        {excerpt && <div className="p-2" dangerouslySetInnerHTML={{__html: excerpt}}></div>}
        {!excerpt && <div><i>There is no excerpt for this notice</i></div>}
      </main>
      <footer className={"py-2 px-4 border-t-1 border-gray-300 flex justify-between"}>
        <div className={"flex flex-col"}>
          <span className={"text-xs text-gray-600"}>{(new Date(date)).toLocaleString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' })} by {author}</span>
          <span className={"text-xs text-gray-600"}>{tags && tags.map(({name}) => name).join(", ")}</span>
        </div>
        <Link to={`${slug}`} className="text-sm">Read full <i className="fal fa-angle-double-right"></i></Link>
      </footer>
    </article>
  </div>
)

export default NoticeCard
