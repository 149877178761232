import React from 'react'
import {Link} from "gatsby";
import capitalize from 'lodash.capitalize'

const TagSection = ({name, posts}) => (
  <div className={"my-6"}>
    <h5 className={"font-semibold text-xl"}>{capitalize(name)}</h5>
    <nav>
      {posts && posts.map(({title, slug}) => (<Link to={`/information/${slug}`}>{title}</Link>)
        )
      }
    </nav>
  </div>
)

export default TagSection
